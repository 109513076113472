import request from "@/utils/request";

/**
 * 商城提交订单
 */
export const submitOrderApi = (data) => {
  return request.post({
    url: "laundry/order/mall_submit",
    data,
  });
};

/**
 * 充值订单
 */
export const rechargeOrderApi = (money) => {
  return request.post({
    url: "laundry/order/recharge_submit",
    data: {
      money,
    },
  });
};

/**
 * 付款订单的预支付会话标识
 */
export const prepayApi = (orderId) => {
  request.post({
    url: "laundry/order/h5pay",
    data: {
      orderId,
    },
  });
};

/**
 * 计算账单
 */
export const cartCheckoutApi = (data) => {
  return request.get({
    url: "cart/checkout",
    data,
  });
};

/*type orderListOptions = {
    pageNumber: number
    pageSize: number
    /!**
     * 1:待付款 2:已付款 3:正在配送 4:已收货
     *!/
    stateType?: 1 | 2 | 3 | 4
    /!**
     * 1:商品 2:家禽代养 3 果树 4 土地 5 活动 6 积分 9 充值 7查询兑换鸡蛋数据
     *!/
    orderType: 1 | 2 | 3 | 4 | 5 | 6 | 9 | 7
    /!**
     * 共享 1 共享中 2 共享结束
     *!/
    feedingStatus?: 1 | 2
    /!**
     * 1打工鸡 2营销鸡
     *!/
    poultryType?: 1 | 2
}*/
/**
 * 订单列表
 * stateType 订单类型分类 1:待付款 2:待接单(已付款) 3:已接单 4:配送中 5:完成
 */
export const orderListApi = (options) => {
  return request.get({
    url: "laundry/order/list",
    data: options,
  });
};

/**
 * 确认收货
 */
export const confirmTakeGoodsApi = (orderId) => {
  return request.post({
    url: "laundry/order/confirm",
    data: {
      orderId,
    },
  });
};

/**
 * 删除订单
 */
export const deleteOrderApi = (orderId) => {
  return request.post({
    url: "laundry/order/delete",
    data: {
      orderId,
    },
  });
};

/**
 * 取消订单
 */
export const cancelOrderApi = (orderId) => {
  return request.post({
    url: "laundry/order/cancel",
    data: {
      orderId,
    },
  });
};

/**
 * 申请退款
 */
export const refundOrderApi = (orderId) => {
  return request.post({
    url: "laundry/order/refund",
    data: {
      orderId,
    },
  });
};

/**
 * 获取订单详情
 */
export const getOrderDetailsApi = (orderId) => {
  return request.get({
    url: "laundry/order/detail",
    data: {
      orderId,
    },
  });
};

/*type SubmitAdoptOrderOptions = {
    /!** 地址id *!/
    addressId: number
    /!** 私人定制列表 *!/
    feedingPersonalIds?: number[]
    /!** 共享信息id *!/
    feedingPoultryId: number
    /!** 寄语 备注 *!/
    message?: string
    /!** 宝宝名称 *!/
    name: string
    /!** 数量 *!/
    number: number
    /!** 寄语 *!/
    sendWord: string
    /!** 店铺id *!/
    storeId: number
    deviceCode?: string[]
}*/
/**
 * 家禽共享提交订单
 */
export const submitAdoptAnimalOrderApi = (data) => {
  return request.post({
    url: "laundry/order/feeding_poultry_submit",
    data,
  });
};

/**
 * 认养提交订单界面获取脚环编码
 */
export const getDevCodeApi = (id) => {
  return request.get({
    url: `device/getDeviceCode?poultryId=${id}`,
  });
};

/**
 * 果树共享提交订单
 */
export const submitAdoptFruitOrderApi = (data) => {
  return request.post({
    url: "laundry/order/feeding_tree_submit",
    data,
  });
};

/**
 * 土地认养订单
 */
export const submitAdoptLandOrderApi = (data) => {
  return request.post({
    url: "laundry/order/feeding_land_submit",
    data,
  });
};

/*type ActivitySubmitOrder = {
    number: number
    feedingActivityId: number
    reportName: string
    reportPhone: string
}*/
/**
 * 活动提交订单
 */
export const activitySubmitOrderApi = (data) => {
  return request.post({
    url: "laundry/order/activity_submit",
    data,
  });
};

/**
 * 积分兑换订单提交
 */
export const pointGoodsSubmitApi = (data) => {
  return request.post({
    url: "laundry/order/point_goods_submit",
    data,
  });
};

/**
 * 支付
 */
export const orderPcPay = (data) => {
  return request.post({
    url: "/laundry/order/pcPay",
    data,
  });
};
