<template>
  <div>
    <Header />
    <div class="banner">会员中心 优质服务</div>
    <el-container>
      <el-aside width="245px" style="background: #f8f8f8; min-height: 800px">
        <div class="handeTitle" @click="$router.push('/user/userInfo')">
          <svg-icon icon-class="user-center"></svg-icon>
          　会员中心
        </div>
        <div>
          <el-menu class="el-menu-vertical-demo" :default-active="activeMenuItem" background-color=" #F8F8F8" text-color="#666" @open="handleOpen" @close="handleClose" router>
            <el-submenu :index="item.path" style="text-align: center" v-for="(item, index) in userRouter" :key="index">
              <template slot="title">
                <!--                <i class="el-icon-location"></i>-->
                <svg-icon :icon-class="item?.meta?.icon"></svg-icon>
                <span style="margin-left: 20px">{{ item?.meta?.title }}</span>
              </template>
              <template v-if="item.children && item.children.length > 0">
                <el-menu-item v-if="!route?.meta?.hidden" style="text-align: left; padding-left: 100px" :index="item.path + '/' + route.path" v-for="(route, i) in item.children" :key="route.path" :route="route">
                  {{ route?.meta?.title }}
                </el-menu-item>
              </template>
            </el-submenu>
          </el-menu>
        </div>
      </el-aside>
      <el-main>
        <router-view />
      </el-main>
    </el-container>
    <Footer />
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";
import router from "@/router";
export default {
  name: "Layout",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      userRouter: [],
      activeMenuItem: "/user_adopt",
    };
  },
  mounted() {
    this.userRouter = router.options.routes.filter((item) => item.path.includes("/user")).filter((item) => !item.redirect);
  },
  methods: {
    handleOpen(key, keyPath) {
      // this.activeMenuItem=e
      // console.log(key);
      // console.log(keyPath);
    },
    handleClose(e) {
      this.activeMenuItem = "";
    },
  },
};
</script>

<style scoped lang="scss">
body {
  padding: 0;
  margin: 0;
}

.banner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 215px;
  border: 1px solid;
  position: relative;
  margin-bottom: 30px;
  box-sizing: border-box;
  background-image: url(~@/assets/user/user-banner.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: 100%;
  background-size: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 42px;
  color: #ffffff;
  letter-spacing: 10px;
}

/* 添加样式 */
.el-container {
  margin-bottom: 50px;
  padding: 0 130px;
  box-sizing: border-box;

  .handeTitle {
    height: 74px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #42a747;
    font-size: 22px;
    border-bottom: 1px solid #42a747;
    position: relative;

    &::after {
      content: "";
      width: 100%;
      height: 10px;
      background-color: #42a747;
      position: absolute;
      top: 0;
      left: 0;
    }

    .img {
      width: 28px;
      height: 28px;
      background-image: url("https://yikerenyang.oss-cn-beijing.aliyuncs.com/tp06fs75gph09qmzherv.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 20px;
    }
  }

  .el-menu {
    ::v-deep .el-submenu__title {
      padding: 0 !important;
      font-size: 22px;

      &:hover {
        background-color: #ebf9ec !important;
      }
    }
    .el-menu-item {
      font-size: 20px;
      &:hover {
        background-color: #ebf9ec !important;
      }
    }
  }
}

.el-main {
  padding: 0 0 0 40px;
}
</style>
