<template>
  <div class="head-wrap">
    <div class="head-top">
      <div class="left">
        <router-link class="link" v-for="item in NavigationLeft" :key="item.url" :to="item.url">
          {{ item.label }}
        </router-link>
      </div>
      <div class="right">
        <el-dropdown v-if="token" trigger="click" @command="handleUserClick">
          <span class="el-dropdown-link">
            <el-avatar :size="'small'" :src="userInfo.avatar" icon="el-icon-user-solid" style="margin-bottom: -10px"></el-avatar>
            {{ userInfo.nickname }}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-user" command="user">会员中心</el-dropdown-item>
            <el-dropdown-item icon="el-icon-lock" command="logOut">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <template v-else>
          <router-link to="/login" class="link">立即登录</router-link>
          <router-link to="/login/register" class="link">立即注册</router-link>
          <!--          <router-link to="/login/register" class="link">立即注册</router-link>-->
        </template>

        <a class="link" v-for="item in NavigationRight" :key="item.url" :href="item.url">{{ item.label }}</a>
        <!--        <el-dropdown trigger="click">
          <span
            class="el-dropdown-link"
            style="color: #333; padding-left: 15px"
          >
            网站导航<i class="el-icon-arrow-down el-icon&#45;&#45;right"></i>
          </span>
                    <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="el-icon-plus">黄金糕</el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-plus"
              >狮子头</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-circle-plus-outline"
              >螺蛳粉</el-dropdown-item
            >
            <el-dropdown-item icon="el-icon-check">双皮奶</el-dropdown-item>
            <el-dropdown-item icon="el-icon-circle-check"
              >蚵仔煎</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>-->
      </div>
    </div>
    <div class="content">
      <a :href="urlBase">
        <img src="../assets/logo.png" class="logo" alt="" />
      </a>
      <div class="search">
        <el-input placeholder="请输入关键词" prefix-icon="el-icon-search" v-model="keyword" clearable @keyup.enter="handleSearch" @clear="handleSearch"></el-input>
        <div style="margin-top: 10px">
          <a v-for="(item, index) in quickList" :key="index" :href="urlBase + 'search/index?keyword=' + item.label">
            {{ item.label }}
          </a>
        </div>
        <el-button type="primary" round @click="handleSearch">搜索</el-button>
      </div>
      <el-dropdown>
        <img src="../assets/qrcode-lg.png" class="qrcode-sm" alt="" />
        <div class="tips">扫码进入小程序</div>
        <el-dropdown-menu slot="dropdown" style="box-shadow: none; background-color: unset; border: none">
          <el-dropdown-item>
            <img class="qrcode-lg" src="../assets/qrcode-lg.png" alt="" />
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import utils from "@/utils";
import { mapGetters } from "vuex";

export default {
  name: "PcShoppingHeader",

  data() {
    return {
      NavigationLeft: [
        {
          label: "首页",
          url: "/index",
        },
        {
          label: "关于我们",
          url: "/other/about-us",
        },
      ],
      NavigationRight: [
        /* {
          label: "客服中心",
          url: "/index",
        },*/
        {
          label: "商家中心",
          url: utils.getStoreBase(),
        },
      ],
      quickList: [
        { label: "认养土鸡", url: "/" },
        { label: "散养鸡", url: "/" },
        { label: "桃树", url: "/" },
        { label: "黑山猪", url: "/" },
        { label: "鸡蛋", url: "/" },
        { label: "土鸡蛋", url: "/" },
        { label: "脐橙", url: "/" },
        { label: "走地鸡", url: "/" },
      ],
      keyword: "",
      urlBase: utils.getUrlBase(),
    };
  },
  computed: {
    ...mapGetters(["userInfo", "token"]),
  },

  mounted() {
    if (this.$route.query.keyword) {
      this.keyword = this.$route.query.keyword;
    }
  },

  methods: {
    handleSearch() {
      // this.$router.push("/search/index?keyword=" + this.keyword);
      window.location.href = this.urlBase + "search/index?keyword=" + this.keyword;
    },
    handleUserClick(key) {
      console.log(key);
      switch (key) {
        case "user":
          this.$router.push("/user");
          break;
        case "logOut":
          this.$store.dispatch("logOut");
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-dropdown-menu {
  .qrcode-lg {
    width: 200px;
    height: 200px;
  }
}
.head-wrap {
  height: 174px;
  // border-bottom: 4px solid #42A747;
  border-bottom: 1px solid #eee;
  box-sizing: border-box;
  font-size: 12px;

  a,
  .router-link {
    &:hover {
      color: #42a747 !important;
    }
  }

  .head-top {
    height: 44px;
    padding: 0 130px;
    line-height: 44px;
    border-bottom: 1px solid #eeeeee;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
      .link {
        border-right: 2px solid #ccc;
        padding: 0 10px;

        &:last-child {
          border: none;
        }
      }
    }

    .right {
      display: block;
      .el-dropdown-link {
        cursor: pointer;
        color: #42a747;
        font-size: 12px;
      }

      .link {
        padding: 0 15px;
        border-right: 2px solid #ccc;
        &:last-child {
          border: none;
        }
      }
    }
  }

  .content {
    padding: 0 130px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;

    .logo {
      width: 235px;
      height: 74px;
    }
    .qrcode-sm {
      width: 78px;
      height: 78px;
    }
    .qrcode-lg {
      width: 200px !important;
      height: 200px !important;
    }
    .tips {
      font-size: 12px;
      color: #39bf3e;
    }
    .search {
      width: 667px;
      height: 54px;
      border: 1px solid #39bf3e;
      border-radius: 27px;
      position: relative;

      a,
      .router-link {
        color: #999 !important;
        padding: 0 10px;
        border-right: 1px solid #999;

        &:nth-last-child(1) {
          border: none;
        }

        &:hover {
          color: #42a747 !important;
        }
      }

      .el-input {
        width: 667px;
        height: 54px;

        ::v-deep .el-input__inner {
          height: 54px !important;
          border-radius: 27px;
          padding-right: 130px;
        }
      }

      .el-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 125px;
        height: 54px;
        border-radius: 27px;
      }
    }
  }
}
</style>

<style>
.el-dropdown-menu__item {
  &:hover {
    background-color: unset !important;
  }
}
</style>
