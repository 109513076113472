import request from "@/utils/request";

/**
 * 获取评论列表
 * @param options   type 1 商品 2 共享
 *                  level 1 好评 2 中评 3 差评
 *                  showType 是否有图片
 *                  valueId 商品id
 */
export const getCommentListApi = (data) => {
  return request.get({
    url: "comment/list",
    data,
  });
};

/**
 * 发表评论
 */
export const pushCommentApi = (data) => {
  return request.post({
    url: "comment/post",
    data,
  });
};

/**
 * 发表投诉建议
 * @param data
 */
export const addComplaintApi = (data) => {
  return request.post({
    url: "feedback/submit",
    data,
  });
};

/**
 * 获取商品评价列表
 * productId 商品ID
 * orderType 商品分类 1商品  2家禽   3果树   4土地
 * evaluationType  评价类型1好评 2差评
 * withPicture 是否有图 0否 1是
 */
export const evaluationRecordApi = (data) => {
  return request.get({
    url: "comment/evaluationRecord",
    data,
  });
};
