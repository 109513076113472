import request from "@/utils/request";

/**
 * 获取用户信息
 */
export const userInfoApi = () => {
  return request.get({
    url: "user/info",
  });
};

/**
 * 用户签到
 */
export const signInApi = () => {
  return request.post({
    url: "user/report",
  });
};

/**
 * 签到列表
 */
export const singInListApi = (data) => {
  return request.get({
    url: "user/list",
    data,
  });
};

/**
 * 签到天数信息
 */
export const singInCountInfoApi = () => {
  return request.post({
    url: "user/report_index",
  });
};

/**
 * 入驻协议
 */
export const joinInAgreementApi = () => {
  return request.get({
    url: "user/entry_agreement",
  });
};

/**
 * 用户信息修改
 */
export const updateUserInfoApi = (data) => {
  return request.post({
    url: "user/update",
    data,
  });
};

/**
 * 用户注册
 * username、password、mobile、code
 * @param data username用户名 password密码 mobile手机号 code验证码
 * @returns
 */
export const userRegister = (data) => {
  return request.post({
    url: "auth/register",
    data,
  });
};

/**
 * 获取验证码
 * @param data mobile 手机号
 * @returns
 */
export const getSmSCode = (data) => {
  return request.post({
    url: "auth/regCaptcha",
    data,
  });
};
