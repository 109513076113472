import request from "@/utils/request";

/*type AddCartOptions = {
    /!**
     * 商品id
     *!/
    goodsId: number
    /!**
     * 商品名称
     *!/
    goodsName: string
    /!**
     * 商品···
     *!/
    goodsSn: string
    /!**
     * 数量
     *!/
    number: number
    picUrl: string
    price: number
    /!**
     * 规格id
     *!/
    productId: number
    /!**
     * 规格描述
     *!/
    specifications: string[]
    /!**
     * 商城id
     *!/
    storeId: number
    storeLogo: string
    storeName: string
}*/

/**
 *  添加购物车
 */
export const addCartApi = (data) => {
  return request.post({
    url: "cart/add",
    data,
  });
};

/**
 * 购物车列表
 */
export const getCartListApi = () => {
  return request.get({
    url: "cart/index",
  });
};

/**
 * 购物车切换状态
 */
export const changeCartCheckedApi = (data) => {
  return request.post({
    url: "cart/checked",
    data,
  });
};

/**
 * 删除购物车
 */
export const cartDeleteApi = (data) => {
  return request.post({
    url: "cart/delete",
    data,
  });
};

/*type cartFastAddOptions = {
    freightTemplateId: number
    goodsId: number
    goodsName: string
    goodsSn: string
    isPostage: number
    number: number
    picUrl: string
    price: number
    /!**
     * 规格id
     *!/
    productId: number
    /!**
     * 规格
     *!/
    specifications: any[]
    /!**
     * 店铺id
     *!/
    storeId: number
    /!**
     * 店铺logo
     *!/
    storeLogo: string
    /!**
     * 店铺名称
     *!/
    storeName: string
}*/
/**
 * 立即购买 生成购物车
 */
export const cartFastAddApi = (data) => {
  return request.post({
    url: "cart/fastadd",
    data,
  });
};

/**
 * 计算账单
 */
export const cartCheckoutApi = (data) => {
  return request.get({
    url: "cart/checkout",
    data,
  });
};
