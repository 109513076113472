import request from "@/utils/request";

/**
 * 收藏商品或者共享
 * type 1:商品 2:家禽
 */
export const changeCollectApi = (data) => {
  return request.post({
    url: "collect/addordelete",
    data,
  });
};

/**
 * 获取收藏列表
 */
export const getCollectListApi = (data) => {
  return request.get({ url: "collect/list", data });
};
