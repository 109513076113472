import request from "@/utils/request";

/**
 * 登陆接口
 */
export const loginApi = (data) => {
  return request.post({
    url: "auth/login",
    data,
  });
};
/**
 * 找回密码
 */
export const loginReset = (data) => {
  return request.post({
    url: "auth/reset",
    data,
  });
};

/**
 * 微信登陆
 */
export const wxLoginApi = (data) => {
  return request.post({
    url: "auth/login_by_weixin",
    data,
  });
};
// pc微信登陆
export const wxLoginPc = (data) => {
  return request.post({
    url: "auth/login_by_pc?code=" + data.code,
    data,
  });
};

/**
 * 登出
 */
export const logoutApi = () => {
  return request.get({
    url: "auth/logout",
  });
};

/**
 * 绑定手机号
 */
export const bindPhoneApi = (data) => {
  return request.post({
    url: "auth/bindPhone",
    data,
  });
};
