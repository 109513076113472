import request from "@/utils/request";

/**
 * 打工鸡奖励规则说明
 */
export const RewardRulesApi = () => {
  return request.get({
    url: "sys_config/reward_rules",
  });
};

/**
 * deviceId-设备id
 * 云台控制-type
 * left：向左；right：向右；up：向上；down：向下；zoomin：缩小；zoomout：放大；stop：停止
 */
export const cloudControl = (data) => {
  return request.post({
    url: "device/cloud_control",
    data,
  });
};

export const getSecurityFilingApi = () => {
  return request.get({
    url: "sys_config/copyright_info",
  });
};
