import request from "@/utils/request";

/**
 * 账户详情
 */
export const accountDetailsApi = () =>
  request.get({
    url: "account/index",
  });

// type AddBankCardOptions = {
//     accountId: number
//     bankCode: string
//     bankOriginalName: string
//     bankUserName: string
//     captcha: string
//     bankIdCard: string
//     [key: string]: any
// }

/**
 * 添加银行卡
 */
export const addBankCardApi = (data) => {
  return request.post({
    url: "account/addBankCard",
    data,
  });
};

/**
 * 获取绑定银行卡验证码
 */
export const getCaptchaApi = () => {
  return request.post({
    url: "account/captcha",
  });
};

/**
 * 绑定支付密码
 */
export const updatePwApi = (data) => {
  return request.post({
    url: "account/updatePPW",
    data,
  });
};

/**
 * 删除银行卡
 */
export const deleteBankCardApi = (id) => {
  return request.post({
    url: `account/deleteBankCard/${id}`,
  });
};

// type FinancialDetailsApiOptions = {
//     /** 账户id */
//     accountId: number
//     limit: number
//     page: number
// }

/**
 * 财务明细
 */
export const financialDetailsApi = (data) => {
  return request.get({
    url: "account/records",
    data,
  });
};

/**
 * 提现
 */
export const withdrawalApi = (data) => {
  return request.post({
    url: "account/drawableMoney",
    data,
  });
};

/**
 * 提现记录
 */
export const withdrawalRecordApi = (data) => {
  return request.get({
    url: "account/drawable/records",
    data,
  });
};

/**
 * 积分记录
 */
export const pointRecordsApi = (data) => {
  return request.get({
    url: "account/point_records",
    data,
  });
};

/*type JoinInOptions = {
    /!** 农场信息 *!/
    adminFarmInfo: {
        /!** 农场地址 *!/
        address: string
        /!** 营业执照 *!/
        businessLicensePics: string
        /!** 城市 *!/
        city: string
        /!** 店铺详情 *!/
        detail: string
        /!** 农场logo *!/
        farmLogo: string
        /!** 农场类型 *!/
        farmType?: 1 | 2
        /!** 身份证照片 *!/
        idCardPics: string
        /!** 姓名 *!/
        name: string
        /!** 农场主名称 *!/
        ownerName: string
        /!** 手机号 *!/
        phone: string
    }
    /!** 店铺信息 *!/
    adminStoreInfo: {
        /!** 账户 *!/
        adminOwnerAccount: string
        /!** 账户密码 *!/
        adminPassword: string
        /!** 保证金 *!/
        earnestMoney?: number
        isDrawableMoney?: number
        isPayEarnestMoney?: number
        /!** 弄成猪名称 *!/
        ownerName: string
        /!** 身份证 *!/
        storeIdCard: string
        /!** logo *!/
        storeLogo: string
        /!** 店铺名称 *!/
        storeName: string
        /!** 手机号 *!/
        storePhone: string
        /!** 状态 *!/
        storeStatus?: number
        /!** 店铺类型 1 个人 2 企业 *!/
        storeType: 1 | 2
    }
    captcha: string
    mobile: string
}*/
/**
 * 入驻
 */
export const joinInApi = (data) => {
  return request.post({
    url: "account/add_store",
    data,
  });
};

/** 店铺详情 */
export const farmInfoApi = (id) => {
  return request.get({
    url: `account/farmInfo/${id}`,
  });
};

/** 店铺销量 */
export const farmOrderTotalApi = (id) => {
  return request.get({
    url: `account/countSales/${id}`,
  });
};

/** 根据手机号发送验证码 */
export const sendCodeToPhoneApi = (mobile) => {
  return request.post({
    url: "account/captcha_by_phone",
    data: mobile,
  });
};
